<template>
  <div>
    <header-menu ref="HeaderMenu"></header-menu>
    <div class="view-content">
      <div class="banner">
        <div class="desc__box text_left">
          <h1 class="banner_title b_b_space font-size_60">
            S2P全场景营销管理解决方案
          </h1>
          <p class="banner_desc b_b_space font-size_20">
            S2P适用多渠道全模式。包含OTC零售、处方临床、基层第三终端广阔市场、商务渠道
            等多种团队模式，帮助企业以数据持续沉淀与应用为核心，建立“数据化管理平台”，赋能营销决策。
          </p>
          <div class="banner_desc_case b_b_space font-size_20">
            <div class="banner_desc_case_1 b_b_space font-size_20">
              <h3 style="border-bottom: 1px solid #dbdbdb;font-size:10pt;font-weight:1000;color:#008CE6;text-align: center;padding-bottom:0.2rem">S2P适用多渠道全模式：</h3>
              <p class="b_b_space font-size_16" style="font-size:9pt;padding-top:0.1rem">
                OTC零售、处方临床、基层第三终端广阔市场、商务渠道、招商代理、市场推广、混合制等多种团队模式。
              </p>
            </div>
           <div class="banner_desc_case_1 b_b_space font-size_20">
              <h3 style="border-bottom: 1px solid #dbdbdb;font-size:10pt;font-weight:1000;color:#008CE6;text-align: center;;padding-bottom:0.2rem">S2P适用多客户全层级：</h3>
              <p class="b_b_space font-size_16" style="font-size:9pt;padding-top:0.1rem">
                以医院、医生、药店、连锁、基层、配送商、服务商为对象；以业务人员拜访活动为主线；行为指标体系为依据；协同协访、业务指导，以数据持续沉淀与应用为核心，建立“数据化管理平台”，赋能营销决策。
              </p>
            </div>
            <div class="banner_desc_case_1 b_b_space font-size_20">
              <h3 style="border-bottom: 1px solid #dbdbdb;font-size:10pt;font-weight:1000;color:#008CE6;text-align: center;;padding-bottom:0.2rem">S2P适用多类型全过程：</h3>
              <p class="b_b_space font-size_16" style="font-size:9pt;padding-top:0.1rem">
                正也S2P管理平台可以为医药营销管理提供——辖区管理、客户关系管理、执行过程管理、销售指标管理、市场活动管理、学术会议管理和多维度多权限的可视化智能报表，解决当前面临的“多产品多渠道多模式”的管理问题。
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="l_r_space t__space informatization_1">
        <h2 class="nav__title">医药全场景营销的管理问题</h2>
        <div style="padding-top: 0.7rem">
          <a-row :gutter="140" type="flex">
            <a-col
              class="space_p_b-four"
              :xs="24"
              :sm="12"
              :md="12"
              :lg="12"
              :xl="8"
              :xxl="8"
              v-for="(item, index) in statusList"
              :key="index"
            >
              <div
                :style="{
                  background: `url(${item.img_url})`,
                  width: item.width,
                  height: item.height,
                }"
                class="bgz"
              ></div>
              <div>
                <h3 class="col_title1 b_b_space font-size_26">
                  {{ item.title }}
                </h3>
                <p class="col_desc1 text_left font-size_16">{{ item.desc }}</p>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="l_r_space t__space bgc_f4fbff informatization_2">
        <h2 class="nav__title">我们能帮您做什么</h2>

        <div class="tab_informatization" style="padding-top: 0.7rem">
          <a-tabs
            default-active-key="1"
            :tabBarStyle="{
              borderBottom: 'none',
            }"
          >
            <a-tab-pane key="1" tab="精细化客户管理">
              <div class="space_p_t-six">
                <a-row :gutter="120" type="flex">
                  <a-col
                    class="space_p_b-four"
                    :xs="24"
                    :sm="12"
                    :md="12"
                    :lg="8"
                    :xl="8"
                    :xxl="8"
                    v-for="(item, index) in helpList"
                    :key="index"
                  >
                    <div
                      :style="{
                        background: `url(${item.img_url})`,
                        width: '76px',
                        height: '76px',
                      }"
                      class="bgz"
                    ></div>
                    <div>
                      <h3 class="col_title2 b_b_space font-size_24">
                        {{ item.title }}
                      </h3>
                      <p class="col_desc1 b_b_space text_left font-size_16">
                        {{ item.desc }}
                      </p>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="优效化过程管理">
              <div class="space_p_t-six">
                <a-row :gutter="120" type="flex">
                  <a-col
                    class="space_p_b-four"
                    :xs="24"
                    :sm="12"
                    :md="12"
                    :lg="8"
                    :xl="6"
                    :xxl="6"
                    v-for="(item, index) in helpList1"
                    :key="index"
                  >
                    <div
                      :style="{
                        background: `url(${item.img_url})`,
                        width: '76px',
                        height: '76px',
                      }"
                      class="bgz"
                    ></div>
                    <div>
                      <h3 class="col_title2 b_b_space font-size_24">
                        {{ item.title }}
                      </h3>
                      <p class="col_desc1 b_b_space text_left font-size_16">
                        {{ item.desc }}
                      </p>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </a-tab-pane>
            <a-tab-pane key="3" tab="驱动型绩效管理">
              <div class="space_p_t-six">
                <a-row :gutter="120" type="flex">
                  <a-col
                    class="space_p_b-four"
                    :xs="24"
                    :sm="12"
                    :md="12"
                    :lg="8"
                    :xl="8"
                    :xxl="8"
                    v-for="(item, index) in helpList2"
                    :key="index"
                  >
                    <div
                      :style="{
                        background: `url(${item.img_url})`,
                        width: '76px',
                        height: '76px',
                      }"
                      class="bgz"
                    ></div>
                    <div>
                      <h3 class="col_title2 b_b_space font-size_24">
                        {{ item.title }}
                      </h3>
                      <p class="col_desc1 b_b_space text_left font-size_16">
                        {{ item.desc }}
                      </p>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
      <div class="l_r_space t__space informatization_3">
        <h2 class="nav__title">S2P优势</h2>
        <p class="nav__desc font-size_24" style="padding: 0.15rem 0 0.9rem 0">
          S2P综合平台下的CRM管理系统可实现一套系统含盖RX、OTC、分销三个营销模式下的移动端管理
        </p>
        <a-row :gutter="120" type="flex">
          <a-col
            class="space_p_b-four"
            :xs="24"
            :sm="12"
            :md="12"
            :lg="8"
            :xl="8"
            :xxl="8"
            v-for="(item, index) in advantageList"
            :key="index"
          >
            <div
              :style="{
                background: `url(${item.img_url})`,
                width: item.width,
                height: item.height,
              }"
              class="bgz"
            ></div>
            <div>
              <h3 class="col_title3 b_b_space font-size_26">
                {{ item.title }}
              </h3>
              <p class="col_desc1 font-size_16" style="text-align: center">
                {{ item.desc }}
              </p>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "@/components/Footer";
import HeaderMenu from "@/components/HeaderMenu";
import directSelling_1 from "@/assets/img/png/direct-selling_1.png";
import directSelling_2 from "@/assets/img/png/direct-selling_2.png";
import directSelling_3 from "@/assets/img/png/direct-selling_3.png";
import directSelling_4 from "@/assets/img/png/direct-selling_4.png";
import directSelling_5 from "@/assets/img/png/direct-selling_5.png";
import directSelling_6 from "@/assets/img/png/direct-selling_6.png";
import directSelling_7 from "@/assets/img/png/direct-selling_7.png";
import directSelling_8 from "@/assets/img/png/direct-selling_8.png";
import directSelling_9 from "@/assets/img/png/direct-selling_9.png";
import informatization_7 from "@/assets/img/png/informatization_7.png";
import informatization_8 from "@/assets/img/png/informatization_8.png";
import informatization_9 from "@/assets/img/png/informatization_9.png";
import informatization_15 from "@/assets/img/png/informatization_15.png";

export default {
  name: "directSelling",
  components: { Footer, HeaderMenu },
  data() {
    return {
      statusList: [
        {
          title: "多元化营销的模式管理",
          width: "76px",
          height: "77px",
          desc: "OTC零售、处方临床、基层医疗、第三终端、商务渠道、招商代理、市场推广、混合制等多种团队模式，并且随着业务的发展与深耕，营销模式也在适应市场而变化调整，如何使用一套专业的医药营销管理系统满足企业兼容当下、面向未来的营销管理。",
          img_url: directSelling_4,
        },
        {
          title: "多模式增长的机会洞察",
          width: "83px",
          height: "83px",
          desc: "不同产品、不同渠道、不同模式的采用，不同的基础市场与不同的市场容量下，深度挖掘销售增长的机会。",
          img_url: directSelling_1,
        },
        {
          title: "多类型活动的资源匹配",
          width: "83px",
          height: "81px",
          desc: "人员的投入、会议的投入、市场活动的投入是否起到效果？对业绩的增长有无帮助？",
          img_url: directSelling_2,
        },
      ],
      helpList: [
        {
          title: "行业标准数据",
          desc: "涵盖100万计医生、3万+医院、49万+基层医疗、52万+药店、8千+连锁总部、8千+配送商。支持主数据清洗服务，帮助企业确认客户真实性，去除重复无效客户。",
          img_url: informatization_7,
        },
        {
          title: "辖区与客户关系维护",
          desc: "维护辖区与客户的负责关系，按月存储辖区客户关系。",
          img_url: informatization_8,
        },
        {
          title: "客户拜访指标",
          desc: "划分客户类型、定义客户级别，自建客户标签，针对不同标签的客户设置不同的拜访频次，最终为营销团队形成符合实际业务的KPI报表。",
          img_url: informatization_9,
        },
      ],
      helpList1: [
        {
          title: "拜访计划与执行",
          desc: "提前制定拜访计划，合理规划拜访路线，同时，后台管理阶段性产品推广主题与内容。",
          img_url: informatization_7,
        },
        {
          title: "市场活动与反馈",
          desc: "支持市场营销活动，终端促销活动，各级学术活动，商务活动，准入性活动等。",
          img_url: informatization_8,
        },
        {
          title: "拜访指标与目的",
          desc: "可针对客户或业务员下达拜访指标，并记录拜访指标、目标达成情况，以及工作进度。",
          img_url: informatization_9,
        },
        {
          title: "主管协访与辅导",
          desc: "针对主管或团队领导的主要职责与完成度、完善度，进行记录、统计、呈现。",
          img_url: informatization_15,
        },
      ],
      helpList2: [
        {
          title: "销售指标",
          desc: "有效的辖区与指标管理，S2P可按客户、产品、辖区下达数量或金额的销售指标，并且可查看指标计划与实际达成。",
          img_url: informatization_7,
        },
        {
          title: "覆盖增长",
          desc: "随时查看各区域销售的覆盖情况，并根据产品覆盖与单产数据，洞察市场增长机会。",
          img_url: informatization_8,
        },
        {
          title: "智能报表",
          desc: "多维度可订制的智能分析报表，帮助决策与管理团队，精准洞悉销售增长机会。",
          img_url: informatization_9,
        },
      ],
      advantageList: [
        {
          title: "内置行业主数据",
          width: "67px",
          height: "76px",
          desc: "销讯通CRM系统内置行业主数据库 ，提供辖区关联管理 ，内置多达150余项医药行业拜访KPI选项亦可自主添加个性化拜访选项",
          img_url: directSelling_7,
        },
        {
          title: "会议流程完整",
          width: "87px",
          height: "76px",
          desc: "销讯通CRM系统支持会前申请，会中执行记录提交，会后分析等全流程化管理",
          img_url: directSelling_8,
        },
        {
          title: "报表多视角",
          width: "88px",
          height: "72px",
          desc: "可视化报表可按医药代表、地区经理、大区经理、销售总监等不同角色的视角进行展现",
          img_url: directSelling_9,
        },
      ],
    };
  },
  methods: {},
};
</script>
<style scoped lang="scss">
@import "@/assets/style/color.scss";
.col_title1 {
  padding: 0.25rem 0 0.12rem 0;
  color: $color-darkBlue;
  text-align: center;
}
.col_desc1 {
  color: $color-fixGray;
  margin-bottom: 0;
}
.col_title2 {
  padding: 0.15rem 0 0.2rem 0;
  font-weight: bold;
}
.col_title3 {
  padding: 0.35rem 0 0.12rem 0;
  color: $color-darkMinBlue;
  font-weight: bold;
}
.banner {
  width: 100%;
  position: relative;
  background: url("~@/assets/img/png/direct-selling_banner.png");
  background-size: 100% 100%;
  height: 8rem;
  overflow: hidden;
  .desc__box {
    position: absolute;
    top: 1.5rem;
    left: 13.5%;
    .banner_title {
      color: $color-lightBlue;
    }
    .banner_desc {
      width: 5.92rem;
      padding-top: 0.45rem;
      margin-bottom: 0;
    }
    .banner_desc_case {
      width: 9rem;
      margin-top: 0.5rem;
      display: flex;
    }
    .banner_desc_case_1{
      width: 2.6rem;
      height: 3rem;
      border-radius: 0.2rem;
      padding: 0.2rem;
      margin-right: 0.15rem;
      background: #ffffff;
    }
  }
}
.informatization_1 {
  padding-bottom: 0.1rem;
}
.informatization_2 {
  padding-bottom: 0.7rem;
}
.informatization_3 {
  padding-bottom: 0.7rem;
}
.bgz {
  background-size: 100% 100%;
  margin: 0 auto;
}
.tab_informatization {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
    .ant-tabs-nav-scroll {
      // color: $color-fixGray;
      // font-size: 0.26rem;
      .ant-tabs-nav .ant-tabs-tab-active {
        color: $color-darkBlue;
        font-weight: bold;
      }
    }
  }
}
.space_p_b-four {
  padding-bottom: 0.4rem;
}
.space_p_t-six {
  padding-top: 0.6rem;
}
</style>
